import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import PageWrapper from '../components/pagewrapper/pageWrapper';

export default function About() {
  return (
    <PageWrapper activeItem={'about'}>
      <Grid centered={true}>
        <Grid.Column
          mobile={12}
          tablet={10}
          computer={8}
          largeScreen={8}
          widescreen={8}
          className={'page-wrapper-container'}
        >
          <h1 className={'page-wrapper-title'}>{'About'}</h1>
          <p className={'hello'}>{'Hello!'}</p>
          <p className={'about-description'}>
            {"My name is Kevin Xu and I'm currently a senior software engineer working at "}
            {
              <a target="_blank" rel="noopener noreferrer" href="http://www.netflix.com">
                Netflix
              </a>
            }
            {' living in the Bay Area.'}
          </p>
          <p className={'about-description'}>
            {
              "I enjoy taking on challenging problems and learning new things each and every day. I'll be using this site primarily to write some blog posts on various topics, as well as to keep track of my progress on goals & books. Still a work in progress, but stay tuned for updates!"
            }
          </p>
          <p className={'about-description'}>
            {'The best way to reach me is via my '}
            {
              <a target="_blank" rel="noopener noreferrer" href="mailto:kevin@kevinjxu.com">
                email
              </a>
            }
            {'.'}
          </p>
        </Grid.Column>
      </Grid>
    </PageWrapper>
  );
}
